import { Controller } from "stimulus"
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    if (this.element.dataset.swiperStyle == "delete") {
      this.swiper = new Swiper(this.element, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        longSwipesRatio: 0
      })
    } else if (this.element.dataset.swiperStyle == "links") {
      this.swiper = new Swiper(this.element, {
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        on: {
          slideChange: function() {
            let slide = this.slides[this.activeIndex]
            Rails.fire(slide.querySelector('a'), 'click') // Submit form
          }
        }
      })
    } else if (this.element.dataset.swiperStyle == "form") {
      let selectTarget = this.selectTarget
      this.swiper = new Swiper(this.element, {
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        on: {
          slideChange: function() {
            let value = this.slides[this.activeIndex].dataset.value
            selectTarget.value = value
            selectTarget.dispatchEvent(new Event('change'))
          }
        }
      })
    } else if (this.element.dataset.swiperStyle == "calendar") {
      this.swiper = new Swiper(this.element, {
        slidesPerView: 1,
        spaceBetween: 0
      })
    } else {
      this.swiper = new Swiper(this.element, {
        slidesPerView: 1.75,
        spaceBetween: 20,
        freeMode: true
      })
    }
  }
  
  previous() {
    this.swiper.slidePrev(200)
  }
  
  next() {
    this.swiper.slideNext(200)
  }

}