import { Controller } from "stimulus"

export default class extends Controller { 
  
  // Nieuwe Android + iOS Turbo apps
  logout(event) {
    if (typeof AndroidInterface !== 'undefined') {
      AndroidInterface.logout()
    } else if (typeof webkit !== 'undefined') {
      webkit.messageHandlers.plango.postMessage({message: "logout"}) 
    }
  }


  // Wordt nog maar voor een paar dingen gebruikt in de instructeursapp (coronacheck + lesvoorbereiding + notities)
  open(event) {
    event.preventDefault()

    if (typeof webkit !== 'undefined') {
      webkit.messageHandlers.plango.postMessage({message: "form", path: event.currentTarget.getAttribute('href')})
    } else if (typeof AndroidInterface !== 'undefined') {
      AndroidInterface.form(event.currentTarget.getAttribute('href'))
    }
  }
  
  // Was even method die ik nodig had voor bugfixes in iOS InstructeursApp. Moet beter in refactor
  openIOS(event) {
    if (typeof webkit !== 'undefined') {
      event.preventDefault()
      webkit.messageHandlers.plango.postMessage({message: "openSafari", value: event.currentTarget.getAttribute('href')})
    }
  }

}