import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    this.element.addEventListener('ajax:before', this.before.bind(this))
    this.element.addEventListener('ajax:complete', this.complete.bind(this))
  }
  
  before(event) {
    if (this.submitting) {
      event.preventDefault()
    } else {
      this.element.dataset.submitting = true
    }
  }
  
  complete(event) {
    this.element.removeAttribute("data-submitting")
  }
  
  get submitting() {
    return this.element.hasAttribute("data-submitting")
  }
  
}