import { Controller } from "stimulus"
import { debounce } from "debounce"
import Swiper from 'swiper'

export default class extends Controller {
  static targets = ["change", "base", "form", "ratings", "ratingName", "swiper", "saveButton"]

  connect() {
    this.styleBars()
    this.changeTarget.style.transition = 'transform .1s ease'

    if (this.hasSwiperTarget) {
      new Swiper(this.swiperTarget, {
        autoHeight: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      })
    }
  }
  
  submitForm = debounce(function() {
    Rails.fire(this.formTarget, 'submit')
  }, 500)

  decrease(event) {
    event.preventDefault()
    this.ratingsTarget.selectedIndex = Math.max(this.current - 1, 0)
    this.styleBars()
    this.submitForm()
  }

  increase(event) {
    event.preventDefault()
    this.ratingsTarget.selectedIndex = Math.min(this.current + 1, this.optionCount)
    this.styleBars()
    this.submitForm()
  }

  saveNote(event) {
    this.submitForm()
    this.saveButtonTarget.style.display = 'none'
  }

  showSaveButton(event) {
    this.saveButtonTarget.style.display = 'block'
  }

  styleBars() {
    let progress = Math.floor(this.current / this.optionCount * 100)
    this.element.classList.toggle('rating-decreasing', progress < this.base)
    this.element.classList.toggle('rating-increasing', progress > this.base)
    this.changeTarget.dataset.progress = progress
    this.changeTarget.style.transform = `translateX(-${100 - progress}%)`
    this.baseTarget.style.transform = `translateX(-${100 - this.base}%)`
    this.ratingNameTarget.innerHTML = this.ratingName
  }

  get ratingName() {
    if (!this.noRating) {
      return this.ratingsTarget.options[this.current].innerText
    } else {
      return "<em>Geen beoordeling</em>"
    }
  }

  get noRating() {
    return this.ratingsTarget.selectedIndex == 0
  }

  get current() {
    if (this.noRating) {
      return this.previous
    } else {
      return this.ratingsTarget.selectedIndex
    }
  }

  get previous() {
    let option = this.ratingsTarget.querySelector(`option[value="${this.ratingsTarget.dataset.previousRatingId}"]`)
    let index = Array.prototype.indexOf.call(this.ratingsTarget.options, option)
    return index == -1 ? 0 : index
  }

  get base() {
    return parseInt(this.baseTarget.dataset.progress)
  }

  get optionCount() {
    return this.ratingsTarget.options.length - 1
  }

}