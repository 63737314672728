import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    setTimeout(function() {
      const event = new Event('exists')
      this.element.dispatchEvent(event)  
    }.bind(this), 100)
    
  }

}